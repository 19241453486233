@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500&display=swap');
body{
  font-family: 'Poppins', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

#cont{
  background: -webkit-linear-gradient(left top, #C6FFDD 0%, #FBD786 100%);
  width: 120px;
  height: 120px;
  border-radius: 500px;
  padding: 5px;
}

#box{
  background: white;
  width: 120px;
  height: 120px;
  border-radius: 500px;
}

.App-header {
  background-color: #ffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

.App-title {
  font-size: 12px;
  margin-top: 0px;
  color: #CCCCCC;
}

.App-margin{
  font-size: 16px;
  margin-bottom: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
